<template>
    <div>
        <LiefengContent>
            <template v-slot:title>内容模板</template>
            <template v-slot:toolsbarRight>
                <Form :model="search" :label-colon="true" :inline="true" class="search">
                    <FormItem label="标题" :label-width="50" style="margin-bottom: 0">
                        <Input v-model.trim="templateName" style="width: 160px"></Input>
                    </FormItem>
                    <FormItem label="菜单名称" :label-width="80">
                        <Input v-model.trim="columnName" style="width: 160px"></Input>
                    </FormItem>
                    <Button type="primary" icon="ios-search" @click="searchFn" style="margin-right: 10px">搜索</Button>
                    <Button type="success" icon="ios-refresh" @click="resetFn">重置</Button>
                </Form>
            </template>
            <template v-slot:contentArea>
                <LiefengTable
                    :talbeColumns="tableColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"

export default {
    components: { LiefengContent, LiefengTable },
    data() {
        return {
            isManager: "",
            buttonRoot: "",
            search: {
                menu: "",
                title: "",
            },
            tableColumns: [
                {
                    title: "菜单名称",
                    key: "columnName",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "span",
                            {
                                style: {
                                    dispiay: "inline-block",
                                    backgroundColor: "#D3F4F4",
                                    padding: "20px 10px",
                                    color: "#24C8C8",
                                },
                            },
                            params.row.columnName
                        )
                    },
                },
                {
                    title: "信息内容标题",
                    minWidth: 500,
                    key: "templateName",
                },
                {
                    title: "状态",
                    width: 120,
                    align: "center",
                    render: (h, params) => {
                        return h("i-Switch", {
                            props: {
                                size: "large",
                                value: params.row.enableStatus == 1,
                            },
                            style: {
                                display: this.isManager == 1 ? "inline-block" : "none",
                            },
                            scopedSlots: {
                                open: () => h("span", "启用"),
                                close: () => h("span", "停用"),
                            },
                            on: {
                                "on-change": () => {
                                    this.changeStatus(params.row)
                                },
                            },
                        })
                    },
                },
                {
                    title: "操作",
                    width: 160,
                    align: "right",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        size: "small",
                                        type: "info",
                                    },
                                    style: {
                                        marginRight: "20px",
                                        display: params.row.enableStatus == 1 && this.buttonRoot == "1001" ? "inline-block" : "none",
                                    },
                                    on: {
                                        click: () => {
                                            this.quote(params.row)
                                        },
                                    },
                                },
                                "引用"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        size: "small",
                                        type: "error",
                                    },
                                    style: {
                                        display: this.isManager == 1 ? "inline-block" : "none",
                                    },
                                    on: {
                                        click: () => {
                                            this.delete(params.row.id)
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
                {
                    title: "创建时间",
                    key: "time",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd") : "")
                    },
                },
                {
                    title: "引用次数",
                    key: "useNum",
                    width: 100,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                },
                                on: {
                                    click: () => {
                                        this.$core.openLayerFrame({
                                            type: 2,
                                            title: "模板引用详情",
                                            content: `/page#/templatedetail?informationId=${params.row.id}&templateName=${params.row.templateName}`,
                                            area: ["100%", "100%"],
                                        })
                                    },
                                },
                            },
                            `引用${params.row.useNum}次`
                        )
                    },
                },
            ],
            tableData: [
                {
                    menu: "信息类",
                    title: "标题1",
                    status: 1,
                    time: "2016-10-03",
                    num: 20,
                },
                {
                    menu: "问卷类",
                    title: "标题2",
                    status: 0,
                    time: "2016-10-03",
                    num: 20,
                },
                {
                    menu: "问卷类",
                    title: "标题2",
                    status: 0,
                    time: "2016-10-03",
                    num: 20,
                },
            ],
            loading: false,
            page: 1,
            pageSize: 20,
            total: 0,

            // 查询条件
            columnName: "",
            templateName: "",
        }
    },
    created() {
        let userButtonRoot = parent.vue.userButtonRoot
        this.isManager = userButtonRoot.includes("INFORMATION:TEMPLATE_CONTROL") ? "1" : "2"
        this.buttonRoot = userButtonRoot.includes("1001") ? "1001" : userButtonRoot.includes("1002") ? "1002" : userButtonRoot.includes("1003") ? "1003" : ""
    },
    mounted() {
        this.getList()
    },
    methods: {
        // 查询
        searchFn() {
            this.getList()
        },
        resetFn() {
            this.page = 1
            this.pageSize = 20
            this.templateName = ""
            this.columnName = ""
            this.getList()
        },
        // 分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 获取表格列表
        getList() {
            this.loading = true
            this.$get("/info/api/pc/information/template/selectPage", {
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                staffId: parent.vue.loginInfo.userinfo.id,
                page: this.page,
                pageSize: this.pageSize,
                templateName: this.templateName,
                columnName: this.columnName,
                isManager: this.isManager,
            }).then(res => {
                this.loading = false
                if (res.code == 200) {
                    this.tableData = res.dataList
                    this.total = res.maxCount
                } else {
                    this.$Message.error({
                        content: "数据获取失败",
                        background: true,
                    })
                }
            })
        },
        // 状态更改
        changeStatus(data) {
            this.$post("/info/api/pc/information/template/updateStatus", {
                templateId: data.id,
                status: data.enableStatus == 1 ? 2 : 1,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "操作成功",
                        background: true,
                    })
                    this.getList()
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    this.getList()
                }
            })
        },
        // 引用q
        quote(data) {
            // this.$get("/info/api/pc/information/template/selectInformationTemplate",{
            //     oemCode:parent.vue.oemInfo.oemCode,
            //     orgCode:parent.vue.loginInfo.userinfo.orgCode,
            //     operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            //     templateId
            // }).then( res => {
            //     if( res.code == 200 ){
            //        this.$Message.success({
            //             content:"操作成功",
            //             background:true
            //         })
            //     }else {
            //         this.$Message.error({
            //             content:res.desc,
            //             background:true
            //         })
            //     }
            // })
            this.$router.push(`${this.$core.funToPage(data)}&templateId=${data.id}`)
        },
        delete(templateId) {
            this.$Modal.confirm({
                title: "删除确定",
                content: "是否删除当前链接",
                onOk: () => {
                    this.$post("/info/api/pc/information/template/deleteInformationTemplate", {
                        templateId,
                        oemCode: parent.vue.oemInfo.oemCode,
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    }).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            this.getList()
                        } else {
                            this.$Message.error({
                                content: res.desc,
                                background: true,
                            })
                            return
                        }
                    })
                },
            })
        },
    },
}
</script>

<style lang="less" scoped>
/deep/.ivu-table-header {
    display: none;
}
</style>